<template >
  <div class="o-component">
    <div class="o-container">
      <article class="o-article">
        <header class="o-article__header a-layout a-layout--clean">
          <span class="o-article__meta u-col-start:3@md u-col-end:11@md">{{ displayDate(props.article.created_at) }}</span>
          <h1 class="o-article__title u-col-start:3@md u-col-end:11@md">{{ props.article.title }}</h1>
          <div class="o-article__intro u-col-start:3@md u-col-end:11@md" v-html="props.article.summary">
          </div>
          <div v-if="props.article.featured_image" class="o-article__hero u-col-start:2@md u-col-end:12@md">
            <picture class="a-image" style="--padding-ratio: calc(1080 / 1920)">
              <img class="a-image__img" width="100%"
                :src="props.article.featured_image?.thumbURL + '?width=5&height=5' ?? ''"
                :data-src="props.article.featured_image?.thumbURL + '?width=1920&height=1080' ?? ''"
                :alt="props.article.featured_image?.alt ?? ''" loading="lazy"/>
            </picture>
          </div>
        </header>
        <div class="o-article__content a-layout a-layout--clean">
          <section class="u-col-start:3@md u-col-end:11@md" v-html="props.article.content"></section>
        </div>
        <div class="o-article__content">
          <template v-for="block in props.blocks">
            <ArticleCallToActionBlock v-if="typeof block.call_to_action !== 'undefined' || block.call_to_action === null"
              :call-to-actions="block.call_to_action ?? []"></ArticleCallToActionBlock>
            <ArticleTitleTextBlock v-if="block.title_text" :title-text="block.title_text">
            </ArticleTitleTextBlock>
            <ArticleImageTextBlock v-if="block.image_text_block" :image-text="block.image_text_block">
            </ArticleImageTextBlock>
          </template>
        </div>
      </article>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import ArticleTitleTextBlock from './ArticleTitleTextBlock.vue';
import ArticleImageTextBlock from './ArticleImageTextBlock.vue';
import ArticleCallToActionBlock from './ArticleCallToActionBlock.vue';
import { CallToActionBlock, ImageTextBlock, TitleTextBlock } from '../../helper/article';

function displayDate(dateString: string): string {
  const date: Date = new Date(dateString)
  return date.toLocaleDateString('default', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })
}

const props = defineProps({
  article: {
    type: Object as PropType<Article>,
    required: true,
  },
  blocks: {
    type: Array<{
      title_text: TitleTextBlock | null,
      image_text_block: ImageTextBlock | null,
      call_to_action: CallToActionBlock[] | null,
    }>,
    default: [],
  }
})

interface Article {
  title: string,
  created_at: string,
  featured_image?: {
    thumbURL: string;
    alt: string;
  },
  summary: string,
  content: string,
}

</script>
